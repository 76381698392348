
export const apiBaseUrl = process.env.REACT_APP_API_URL;
export const reactApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; 

const config = {
  apiBaseUrl,
  reactApiKey, 
};

export default config;
