export const BUSINESS_STEPS = [
  {
    id: 0,
    key: "businessInfoFirstStep",
  },
  {
    id: 1,
    key: "businessSecondStep",
  },
  {
    id: 2,
    key: "businessThirdStep",
  },
];

export const BUSINESS_TYPES = [
  {
    id: 0,
    label: "businessTypePlaceholder",
    value: "",
  },
  {
    id: 1,
    label: "retailers",
    value: "1",
  },
  {
    id: 2,
    label: "manufacturers",
    value: "2",
  },
  {
    id: 3,
    label: "wholesalersDistributors",
    value: "3",
  },
  {
    id: 4,
    label: "eCommercePlatforms",
    value: "4",
  },
  {
    id: 5,
    label: "healthcarePharmaceutical",
    value: "5",
  },
  {
    id: 6,
    label: "logisticsTransportation",
    value: "6",
  },
  {
    id: 7,
    label: "serviceBased",
    value: "7",
  },
];

export const BUSINESS_SETUP_INPUTS_FIRST_STEP = [
  {
    id: 4,
    key: "businessName",
    placeholder: "businessName",
    type: "text",
  },
  {
    id: 6,
    key: "businessType",
    options: BUSINESS_TYPES,
  },
  {
    id: 7,
    key: "businessPhoneNumber",
    placeholder: "700000000",
    type: "tel",
  },
  {
    id: 8,
    key: "businessNationalNumber",
    placeholder: "123456789",
    type: "text",
  },
  {
    id: 12,
    key: "businessAddress",
    placeholder: "businessAddress",
    type: "text",
  },
  {
    id: 13,
    key: "socialMedia",
    placeholder: "",
    type: "text",
  },
];

export const BUSINESS_SETUP_INPUTS_SECOND_STEP = [
  {
    id: 9,
    key: "contactPersonName",
    placeholder: "contactPersonName",
    type: "text",
  },
  {
    id: 10,
    key: "occupation",
    placeholder: "occupation",
    type: "text",
  },
  {
    id: 11,
    key: "contactPhoneNumber",
    placeholder: "700000000",
    type: "tel",
  },
];

export const UPLOAD_FILE_INPUTS = [
  {
    accept: "image/*,application/pdf",
    id: 0,
    label: "tradeLicense",
  },
  {
    accept: "image/*,application/pdf",
    id: 1,
    label: "registrationCertificate",
  },
  {
    accept: "image/*,application/pdf",
    id: 2,
    label: "vatNumber",
  },
  {
    accept: "image/*",
    id: 3,
    label: "iconImage",
    subText: "iconMaxSize",
  },
  {
    accept: "image/*",
    id: 4,
    label: "logoImage",
  },
];

export const FILE_SIZE_LIMIT = 1073741824;
export const MAX_FILE_SIZE = 3 * 1024 * 1024;
