export default {
  deliveryDate: "Drop-off Date",
  deliveryDateIsRequired: "Drop-off date is required",
  deliveryFeeExceedsMaximum: "Delivery fee cannot exceed 20 JD",
  deliveryFeeValueIsNotPositive: "Delivery fee value must be positive number",
  deliveryFeeValueIsRequired: "Drop-off fee value is required",
  deliveryFees: "Delivery Fees",
  deliveryFeesIsRequired: "Delivery Fees fees are required",
  deliveryFeesPlaceHolder: "2 JD",
  dropOffDateTime: "Drop-off Date & Time",
  dropOffFieldToolTip:
    "After Publishing the order, the receiver must confirm or send their location via WhatsApp.",
  dropOffLocation: "Drop-off Location",
  dropOffLocationIsRequired: "Drop Off Location is required",
  editOrder: "Edit Order",
  goodsCategory: "Goods Category",
  goodsCategoryInitialValue: "Choose category",
  goodsCategoryIsRequired: "Goods Category is required",
  locationInitialValue: "Choose location",
  notPaid: "Not paid",
  notPaidOptionToolTip:
    "This option if the Drop-off charges are to be paid by the receiver.",
  note: "Type note",
  notes: "Notes",
  notificationResendedSuccessfully: "Notification resended successfully",
  optional: "Optional",
  orderFees: "Order Amount",
  orderFeesIsNotPositive: "Order fees must be positive number",
  orderFeesIsRequired: "Order Amount is required",
  orderInformation: "Order Information",
  paid: "Paid",
  paidOptionToolTip:
    "This option if the Drop-off charges are to be paid by the sender.",
  pickupDate: "Pickup Date",
  pickupDateIsRequired: "Pickup date is required",
  pickupDateTime: "Pickup Date & Time",
  pickupLocation: "Pickup Location",
  pickupLocationIsRequired: "Pickup Location is required",
  pickupTime: "Pickup Time",
  pickupTimeIsRequired: "Pickup time is required",
  pickupTimeSubText: "(You can choose multiple time slots)",
  placeOrder: "Place Order",
  preferredDropOffTime: "Preferred Drop-off Time",
  preferredDropOffTimeIsRequired: "Preferred drop-off time is required",
  preferredVehicleInitialValue: "Choose vehicle",
  preferredVehicleType: "Preferred Vehicle Type",
  preferredVehicleTypeIsRequired: "Preferred Vehicle Type is required",
  publishOrder: "Publish Order",
  quantity: "Quantity",
  quantityInitialValue: "Choose quantity",
  quantityIsNotPositive: "Quantity must be positive number",
  quantityIsRequired: "Quantity is required",
  receiverFullName: "Receiver Full Name",
  receiverFullNameInvalidCharacters:
    "The name must only contain letters, spaces, and the following allowed characters: ( ' , - , . )",
  receiverFullNameIsRequired: "Receiver Full Name is required",
  receiverPhoneNumber: "Receiver Phone Number",
  receiverPhoneNumberIsRequired: "Receiver Phone Number is required",
  receiverWhatsApp: "Receiver WhatsApp",
  resendWhatsAppMessages: "Resend WhatsApp messages",
  save: "Save",
  saveAsDraft: "Save as Draft",
  saveChanges: "Save Changes",
  scheduleShipmentError:
    "Pickup and drop-off dates must either match or be consecutive days.",
  secondPhoneNumber: "Second Phone Number",
  shipmentDetails: "Shipment Details",
  shipmentSchedule: "Delivery Date & Time",
  shipmentScheduleIsRequired: "Delivery Date & Time are required",
  shipmentScheduleSubtitle:
    "Please choose preferred pickup date/time slots and Drop-off date/ time slots",
  size: "Size",
  sizeInitialValue: "Choose size",
  sizeIsRequired: "Size is required",
  theDriverhasNoConversationForThisOrder:
    "The WhatsApp number is currently busy with another conversation.",
  viewOrder: "View Order",
  waitingForLocationConfirmation: "Waiting for location confirmation",
};
