export default {
  applicationUnderReview: "Your Application is Under Review",
  applicationUnderReviewFirstMessage: "Thank you for your submission!",
  applicationUnderReviewSecondMessage:
    "We are currently reviewing your application.",
  businessAddress: "Business Address",
  businessAddressIsRequired: "Business Address is required",
  businessInfoFirstStep: "Step 1: Business Info.",
  businessNationalNumber: "Business National Number (Optional)",
  businessSecondStep: "Step 2: Contact Details",
  businessThirdStep: "Step 3: Upload Documents",
  contactPersonNameIsRequired: "Contact Person Name is required",
  fileSizeExceedsLimit: "File size must not exceed 3 MB",
  iconHelperText:
    "Choose a simple icon to represent your business in the app! This should be different from your main logo and easy to recognize. This icon will appear on the driver's app",
  iconImage: "Icon Image",
  iconMaxSize: "Size: 233 KB max. (512 * 512 px)",
  loadingProgressMessage: "Loading...  Please wait",
  logoImage: "Logo Image",
  occupation: "Occupation",
  occupationIsRequired: "Occupation is required",
  preview: "Preview",
  registrationCertificate: "Commercial Registration Certificate",
  replace: "Replace",
  required: "Required",
  sizeLimitMessage: "Total files size exceeded the maximum limit",
  socialMedia: "Website / Social Media",
  stepsIndicatorTitle: "Complete your business profile",
  submit: "Submit",
  tradeLicense: "Trade license",
  unsupportedFileFormat: "Unsupported File Format",
  upload: "Upload",
  uploadFollowingDocuments: "Please Upload the following documents",
  vatNumber: "VAT Number",
  yourFilesIsUploading: "Your files are loading",
};
