/**
 * Creates a marker icon configuration.
 *
 * @param {string} url    The URL of the marker icon.
 * @param {number} width  The width of the marker icon.
 * @param {number} height The height of the marker icon.
 *
 * @returns {object}      The marker icon configuration.
 */
export const createMarkerIcon = (url, width, height) => {
  const markerDiv = document.createElement("div");
  markerDiv.style.width = `${width}px`;
  markerDiv.style.height = `${height}px`;
  markerDiv.style.backgroundImage = `url(${url})`;
  markerDiv.style.backgroundSize = "contain";
  markerDiv.style.backgroundRepeat = "no-repeat";
  return markerDiv; 
};
