import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

import {
  allTableRecordsAtom,
  dateValueAtom,
  isContactActiveAtom,
  // isExpeditedAtom,
  isLoginExpiredAtom,
  orderStatusAtom,
  searchQueryAtom,
  totalRecordsAtom,
  userInfoAtom,
} from "atoms";
import { useFilterQueryParams } from "./index";
import { OrderService, SenderContactService, SenderService } from "services";

const senderService = new SenderService();
const senderContactService = new SenderContactService();
const orderService = new OrderService();

export const useTableRecords = (tableType = "orders") => {
  const [allTableRecords, setAllTableRecords] = useAtom(allTableRecordsAtom);
  const [totalRecords, setTotalRecords] = useAtom(totalRecordsAtom);
  const searchQueryValue = useAtomValue(searchQueryAtom);
  const orderStatus = useAtomValue(orderStatusAtom);
  // const isExpedited = useAtomValue(isExpeditedAtom);
  const dateValue = useAtomValue(dateValueAtom);
  const setIsLoginExpiredAtom = useSetAtom(isLoginExpiredAtom);
  const [loading, setLoading] = useState(true);
  const isContactActive = useAtomValue(isContactActiveAtom);
  const { updateFilterParam, getFilterParam } = useFilterQueryParams();
  const page = parseInt(getFilterParam("page"));
  const pageSize = parseInt(getFilterParam("pageSize")) || 10;
  const totalPages = Math.ceil(totalRecords / pageSize);
  const { senderId } = useAtomValue(userInfoAtom);

  const areFiltersReset =
    !allTableRecords.length &&
    !totalRecords &&
    !searchQueryValue &&
    !orderStatus &&
    // isExpedited === null &&
    !dateValue;

  const getTableRecords = async () => {
    try {
      setLoading(true);

      let response;

      if (tableType === "contacts") {
        response = await senderContactService.getContacts(
          page,
          pageSize,
          searchQueryValue,
          isContactActive
        );
        setTotalRecords(response?.data?.totalRecords);
        setAllTableRecords(response?.data?.page);
      } else if (tableType === "addresses") {
        response = await senderService.getAddresses(
          page,
          pageSize,
          searchQueryValue
        );
        setTotalRecords(response.totalRecords);
        setAllTableRecords(response.page);
      } else {
        response = await orderService.getOrders(
          page,
          pageSize,
          searchQueryValue,
          orderStatus,
          // isExpedited,
          dateValue,
          senderId
        );

        setTotalRecords(response?.data?.totalRecords);
        setAllTableRecords(response?.data?.page);
      }
    } catch (error) {
      console.log(error);
      if (error.errorMessage === "expired-token") setIsLoginExpiredAtom(true);
    } finally {
      setLoading(false);
    }
  };

  const isNoOrdersCreated =
    !totalRecords &&
    // isExpedited === null &&
    !orderStatus &&
    !searchQueryValue &&
    !dateValue &&
    !orderStatus;

  useEffect(() => {
    getTableRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    pageSize,
    searchQueryValue,
    orderStatus,
    // isExpedited,
    dateValue,
    isContactActive,
  ]);

  useEffect(() => {
    if (!areFiltersReset && !getFilterParam("page")) {
      updateFilterParam("page", 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryValue, orderStatus, dateValue, isContactActive]);
  // [searchQueryValue, orderStatus, isExpedited, dateValue, isContactActive]

  useEffect(() => {
    if (totalRecords) {
      if (totalPages < page) {
        updateFilterParam("page", totalPages);
      }
      if (isNaN(page) || page < 1) updateFilterParam("page", 1);
      if (isNaN(pageSize) || pageSize < 1) updateFilterParam("pageSize", 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getFilterParam,
    page,
    pageSize,
    totalPages,
    totalRecords,
    updateFilterParam,
  ]);

  return {
    allTableRecords,
    getTableRecords,
    isNoOrdersCreated,
    loading,
  };
};
